import React from "react";
import CompanyLogo from "../src/assets/images/nora-logo.jpeg";

const Header = () => {
  return (
    <main>
      <section className="kc-flex-container kc-center-div kc-bg-black">
        <div className="kc-padding-s kc-center-div kc-text-center">
          <h3 className="kc-cl-white-ish">Follow us on Instagram:</h3>
        </div>
        <div>
          <a
            href="https://www.instagram.com/noramuastore/"
            target="_blank"
            rel="noreferrer"
            className="kc-text-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 512 512"
            >
              <title>ionicons-v5_logos</title>
              <path
                d="M349.33,69.33a93.62,93.62,0,0,1,93.34,93.34V349.33a93.62,93.62,0,0,1-93.34,93.34H162.67a93.62,93.62,0,0,1-93.34-93.34V162.67a93.62,93.62,0,0,1,93.34-93.34H349.33m0-37.33H162.67C90.8,32,32,90.8,32,162.67V349.33C32,421.2,90.8,480,162.67,480H349.33C421.2,480,480,421.2,480,349.33V162.67C480,90.8,421.2,32,349.33,32Z"
                style={({ fillRule: "evenodd" }, { fill: "violet" })}
              />
              <path
                d="M377.33,162.67a28,28,0,1,1,28-28A27.94,27.94,0,0,1,377.33,162.67Z"
                style={({ fillRule: "evenodd" }, { fill: "violet" })}
              />
              <path
                d="M256,181.33A74.67,74.67,0,1,1,181.33,256,74.75,74.75,0,0,1,256,181.33M256,144A112,112,0,1,0,368,256,112,112,0,0,0,256,144Z"
                style={({ fillRule: "evenodd" }, { fill: "violet" })}
              />
            </svg>
          </a>
        </div>
        <div className="kc-padding-s">
          <h3 className="kc-cl-white-ish">Check out our Whatsapp:</h3>
        </div>
        <div>
          <a
            href="https://wa.me/c/18733767706"
            target="_blank"
            rel="noreferrer"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 512 512"
            >
              <title>ionicons-v5_logos</title>
              <path
                d="M414.73,97.1A222.14,222.14,0,0,0,256.94,32C134,32,33.92,131.58,33.87,254A220.61,220.61,0,0,0,63.65,365L32,480l118.25-30.87a223.63,223.63,0,0,0,106.6,27h.09c122.93,0,223-99.59,223.06-222A220.18,220.18,0,0,0,414.73,97.1ZM256.94,438.66h-.08a185.75,185.75,0,0,1-94.36-25.72l-6.77-4L85.56,427.26l18.73-68.09-4.41-7A183.46,183.46,0,0,1,71.53,254c0-101.73,83.21-184.5,185.48-184.5A185,185,0,0,1,442.34,254.14C442.3,355.88,359.13,438.66,256.94,438.66ZM358.63,300.47c-5.57-2.78-33-16.2-38.08-18.05s-8.83-2.78-12.54,2.78-14.4,18-17.65,21.75-6.5,4.16-12.07,1.38-23.54-8.63-44.83-27.53c-16.57-14.71-27.75-32.87-31-38.42s-.35-8.56,2.44-11.32c2.51-2.49,5.57-6.48,8.36-9.72s3.72-5.56,5.57-9.26.93-6.94-.46-9.71-12.54-30.08-17.18-41.19c-4.53-10.82-9.12-9.35-12.54-9.52-3.25-.16-7-.2-10.69-.2a20.53,20.53,0,0,0-14.86,6.94c-5.11,5.56-19.51,19-19.51,46.28s20,53.68,22.76,57.38,39.3,59.73,95.21,83.76a323.11,323.11,0,0,0,31.78,11.68c13.35,4.22,25.5,3.63,35.1,2.2,10.71-1.59,33-13.42,37.63-26.38s4.64-24.06,3.25-26.37S364.21,303.24,358.63,300.47Z"
                style={({ fillRule: "evenodd" }, { fill: "green" })}
              />
            </svg>
          </a>
        </div>
      </section>
      <section className="logo-img">
        <img src={CompanyLogo} alt="Logo img" width="300px" height="200px" />
      </section>
    </main>
  );
};
export default Header;
