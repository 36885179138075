import React from "react";
import { NavLink } from "react-router-dom";

const Navbar = () => {
  return (
    <nav className="kc-mobile-hidden kc-flex-container kc-flex-wrap kc-center-div kc-bg-clean">
      <NavLink to="/" className="nav-links">
        Home
      </NavLink>
      <NavLink to="/femme" className="nav-links">
        Femme
      </NavLink>
      <NavLink to="/curvy" className="nav-links">
        Curvy
      </NavLink>
      <NavLink to="/enfants" className="nav-links">
        Enfants
      </NavLink>
      <NavLink to="/homme" className="nav-links">
        Homme
      </NavLink>
      <NavLink to="/beaute" className="nav-links">
        Beauté
      </NavLink>
    </nav>
  );
};
export default Navbar;
