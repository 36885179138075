import React from "react";
import { NavLink } from "react-router-dom";
import MenuBar from "../src/assets/ionicons/menu.svg";

const MobileNav = () => {
  return (
    <nav className="kc-desktop-hidden kc-center-div kc-text-center">
      <div>
        <button
          onClick={myMenuBar}
          className="kc-button-full kc-button-bg-unset "
        >
          <img src={MenuBar} alt="menubar" width="64px" height="64px" />
        </button>
      </div>
      <div>
        <ul
          className="kc-center-div kc-text-center kc-menu-ul"
          id="kc-button-nav"
        >
          <li>
            <NavLink
              to="/"
              className="kc-text-dec-none kc-cl-heritage-blue kc-responsive-text"
            >
              Home
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/femme"
              className="kc-text-dec-none kc-cl-heritage-blue kc-responsive-text"
            >
              Femme
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/curvy"
              className="kc-text-dec-none kc-cl-heritage-blue kc-responsive-text"
            >
              Curvy
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/enfants"
              className="kc-text-dec-none kc-cl-heritage-blue kc-responsive-text"
            >
              Enfants
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/homme"
              className="kc-text-dec-none kc-cl-heritage-blue kc-responsive-text"
            >
              Homme
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/beaute"
              className="kc-text-dec-none kc-cl-heritage-blue kc-responsive-text"
            >
              Beauté
            </NavLink>
          </li>
        </ul>
      </div>
    </nav>
  );
};
export default MobileNav;

function myMenuBar() {
  var x = document.getElementById("mobilenav-links");
  if (x.style.display === "block") {
    x.style.display = "none";
  } else {
    x.style.display = "block";
  }
}
