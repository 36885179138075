import React from "react";
import { image1, image2, image3 } from "../../../src/assets/images/index.js";
const Home = () => {
  return (
    <main>
      <section
        id="homeHeader"
        style={({ height: "50vh" }, { padding: "2rem" })}
      >
        <div className="slider-wrapper">
          <div className="slider">
            <img
              src={image1}
              alt=""
              id="slide-1"
              style={({ width: "100%" }, { height: "50vh" })}
            />
            <img
              src={image2}
              alt=""
              id="slide-2"
              style={({ width: "100%" }, { height: "50vh" })}
            />
            <img
              src={image3}
              alt=""
              id="slide-3"
              style={({ width: "100%" }, { height: "50vh" })}
            />
            <img src={image1} alt="" id="slide-4" style={{ width: "100%" }} />
          </div>
          <div className="slider-nav">
            <a href="#slide-1" rel="norefferer">
              {null}
            </a>
            <a href="#slide-2" rel="norefferer">
              {null}
            </a>
            <a href="#slide-3" rel="norefferer">
              {null}
            </a>
            <a href="#slide-4" rel="norefferer">
              {null}
            </a>
          </div>
        </div>
      </section>
      <section id="homeBody">
        <div className="kc-flex-container kc-center-div kc-flex-wrap kc-flex-gap-s kc-text-center">
          <div className="kc-card-m kc-round-border-all-s kc-shadow-s kc-clearfix kc-margin-s">
            <img className="kc-card-img" src={image1} alt="product img" />
            <h4>Nom de Produit</h4>
            <p>Information the produit</p>
            <span>PRIX</span>
          </div>
          <div className="kc-card-m kc-round-border-all-s kc-shadow-s kc-clearfix kc-margin-s">
            <img className="kc-card-img" src={image1} alt="product img" />
            <h4>Nom de Produit</h4>
            <p>Information the produit</p>
            <span>PRIX</span>
          </div>
          <div className="kc-card-m kc-round-border-all-s kc-shadow-s kc-clearfix kc-margin-s">
            <img className="kc-card-img" src={image1} alt="product img" />
            <h4>Nom de Produit</h4>
            <p>Information the produit</p>
            <span>PRIX</span>
          </div>
        </div>
      </section>
    </main>
  );
};
export default Home;
