import "./App.css";
import "./kc-template.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./components/Home/Home";
import Navbar from "./Navbar";
import Header from "./Header";
import Femme from "./components/Femme/Femme";
import Curvy from "./components/Curvy/Curvy";
import Enfants from "./components/Enfants/Enfants";
import Homme from "./components/Homme/Homme";
import Beaute from "./components/Beaute/Beaute";
import MobileNav from "./MobileNav";
import "./w3css.css";

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Navbar />
      <MobileNav />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/femme" element={<Femme />} />
        <Route path="/curvy" element={<Curvy />} />
        <Route path="/enfants" element={<Enfants />} />
        <Route path="/homme" element={<Homme />} />
        <Route path="/beaute" element={<Beaute />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
